:root {

    --lightGray: #d2d2d2;
    --dark666: #666;
    --dark333: #333;
    --dark222: #222;
    --dark111: #111;
    --absoluteBlack: black;
}

.myCursor{
    cursor: pointer;
}

/* overflow */
.myOverflowHidden{
    overflow: hidden;
}

/* max width */
.myMaxW65 {
    max-width: 65px !important;
}

.myMaxW80 {
    max-width: 80px !important;
}

.myMaxW100 {
    max-width: 100px !important;
}

.myMaxW100 {
    max-width: 130px !important;
}

.myMaxW200 {
    max-width: 200px !important;
}

.myMaxW300 {
    max-width: 300px !important;
}

.myMaxW400 {
    max-width: 400px !important;
}

.myMaxW500 {
    max-width: 500px !important;
}

.myMaxW600 {
    max-width: 600px !important;
}

.myMaxW632 {
    max-width: 632px !important;
}

.myMaxW700 {
    max-width: 700px !important;
}

.myMaxW800 {
    max-width: 800px !important;
}

.myMaxW1000 {
    max-width: 1000px !important;
}

.myMaxW1200 {
    max-width: 1200px !important;
}

.myMaxW1400 {
    max-width: 1400px !important;
}

/* color */

.myLightGrayColor {
    color: #dfdfdf;
}

.myBronzeColor{
    color: #c66c3b;
}

/* background color */

.myBgWhite {
    background-color: #fff;
}

.myBgDarkGray {
    background-color: var(--dark666);
}

.myBgDark333 {
    background-color: var(--dark333);
}

.myBgDark222 {
    background-color: var(--dark222);
}

.myBgDark111 {
    background-color: var(--dark111);
}

.myBgBlack {
    background-color: var(--absoluteBlack);
}

.myBgLightGray {
    background-color: var(--lightGray);
}
.myBgDarkTrasparent7{
    background: rgba(10, 10, 10, .7);
}

/* color */
.myLightGrayColor{
    color: var(--lightGray)
}


/* link style*/
.noLinkStyle {
    text-decoration: none !important;
    color: #fff !important;
}

/* border */
.myBorderDark{
    border: 1px solid #303030;
}

/* overlay */
.prizeOverlay1{
    background-color: #ffcc0085;
    color: #ffffffcd;
}
.prizeOverlay2{
    background-color: #82828298;
    color: #ffffffcd;
}
.prizeOverlay3{
    background-color: #c66c3b7a;
    color: #ffffffcd;
}
.prizeOverlay1:hover{
    background-color: #ffcc0000;
    color: #ffffff00;
    transition: .3s;
}
.prizeOverlay2:hover{
    background-color: #82828200;
    color: #ffffff00;
    transition: .3s;
}
.prizeOverlay3:hover{
    background-color: #c66c3b00;
    color: #ffffff00;
    transition: .3s;
}